import React, {useEffect, useMemo, useState} from "react";
import { Button, Grid } from "@mui/material";
import Popin from "@/components/common/ui/Popin/Popin";
import SchoolItiParametersService from "@/service/SchoolItiParametersService";
import MobileStepper from "@mui/material/MobileStepper";

function HelperPopinDefault({ isOpen, onClose }) {
    const [activeStep, setActiveStep] = useState(0);
    const [helpText, setHelpText] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await SchoolItiParametersService.getHelpertextRegistrationPopin();
                const { IEL_INFO__HELPERTEXT_REGISTRATION_PROCESS: fetchedHelpText } = response.data;
                setHelpText(fetchedHelpText);
            } catch (error) {
                console.error("Erreur lors de la récupération du texte d'aide d'inscription : ", error);
            }
        };

        fetchData();
    }, []);

    const parsedHelpText = helpText ? JSON.parse(helpText) : { contentSteps: [] };

    const steps = parsedHelpText.contentSteps;

    const finalStep = useMemo(() => activeStep < steps.length - 1, [activeStep, steps.length]);

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            setActiveStep(0);
            onClose();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    if (activeStep < steps.length) {
        return (
            <Popin
                title="Comment inscrire un enfant ?"
                isOpen={isOpen}
            >
                <Grid container spacing={2} sx={{ p: 2 }} direction={"column"} alignItems={"center"}>
                    <Grid item xs={12}>
                        <span dangerouslySetInnerHTML={{ __html: steps[activeStep] }}></span>
                    </Grid>
                    <Grid item xs={12}>
                        <MobileStepper
                            variant="dots"
                            steps={steps.length}
                            position="static"
                            activeStep={activeStep}
                            backButton={null}
                            nextButton={null}
                        />
                    </Grid>
                    <Grid item xs={12} textAlign={"center"}>
                        <Button onClick={handleNext} variant="text">{finalStep ? "Suivant" : "Terminer"}</Button>
                    </Grid>
                </Grid>
            </Popin>
        );
    } else {
        return null;
    }
}

export default HelperPopinDefault;

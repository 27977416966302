import AbstractService from "./AbstractService";
import {Dictionary} from "@/types/main";
import SchoolItiParameters, {BillingPaymentPeriods} from "@/models/SchoolItiParameters";
import {parseDate} from "@/plugins/dateFns";
import store from "@/utils/Store";
import urlJoin from "url-join";

type SchoolItiParametersPartial = Omit<SchoolItiParameters, "LOGO_IMAGE_URL" | "TOOLTIP_IMAGE_URL" | "HAS_TREASURY_ORMC">;

class SchoolItiParametersService extends AbstractService {

    constructor() {
        super("/public/fiti/subscription/dynamic/settings");
    }

    async getSettings() {
        const {data} = await this.get<Dictionary<string>>();
        const schoolYear = parseInt(data.SCHOLAR_YEAR);

        return {
            ...data,
            CB_DELAYED_PAYMENT_DEAD_LINE: parseDate(data.CB_DELAYED_PAYMENT_DEAD_LINE),
            BILLING_REPART__PAYMENT_PERIODS: BillingPaymentPeriods.parse(data.BILLING_REPART__PAYMENT_PERIODS),
            SCHOLAR_YEAR: schoolYear,
            TAX_NOTICE_YEAR: schoolYear - 1,
            WKF_DOC__PICTURE_MANDATORY: data.WKF_DOC__PICTURE_MANDATORY === "true",
            SUBSCRIPTIONS_OPEN: data.SUBSCRIPTIONS_OPEN === "true",
            IEL__SUBSCRIBER_MIN_BIRTH_DATE: parseDate(data.IEL__SUBSCRIBER_MIN_BIRTH_DATE),
            WKF_MODULE__FOSTER_FAMILY_ENABLED: data.WKF_MODULE__FOSTER_FAMILY_ENABLED === "true",
            WKF_MODULE__MANAGE_FAMILY_RATIO_ENABLED: data.WKF_MODULE__MANAGE_FAMILY_RATIO_ENABLED === "true",
            WKF_MODULE__REFUGEE_CLAIMANT_ENABLED: data.WKF_MODULE__REFUGEE_CLAIMANT_ENABLED === "true",
            WKF_MODULE__REVIEW_REQUEST_ENABLED: data.WKF_MODULE__REVIEW_REQUEST_ENABLED === "true",
            PREVIOUS_YEAR_ASSIGNMENT_END_SUBS_DATE: data.PREVIOUS_YEAR_ASSIGNMENT_END_SUBS_DATE ? parseDate(data.PREVIOUS_YEAR_ASSIGNMENT_END_SUBS_DATE) : undefined,
            WKF_IEL__VERIFY_CONTACT_EMAIL: data.WKF_IEL__VERIFY_CONTACT_EMAIL === "true",
            WKF_IEL__VERIFY_CONTACT_PHONE: data.WKF_IEL__VERIFY_CONTACT_PHONE === "true",
            IEL__ASK_PHONE_TRAVELER_INFORMATION_ENABLED: data.IEL__ASK_PHONE_TRAVELER_INFORMATION_ENABLED === "true",
            IEL__ASK_EMAIL_ACTUALITY_ENABLED: data.IEL__ASK_EMAIL_ACTUALITY_ENABLED === "true",
            IEL_INFO__RGPD_POLITIC: data.IEL_INFO__RGPD_POLITIC?.toString(),
            IEL_INFO__WELCOME_INFORMATION: data.IEL_INFO__WELCOME_INFORMATION?.toString(),
            IEL__DISPLAY_FARE: data.IEL__DISPLAY_FARE === "true",
            WKF_DOC__PICTURE_RESTRICT_CONFORMITY: data.WKF_DOC__PICTURE_RESTRICT_CONFORMITY === "true",
            WKF_IEL__WISH_COMMENT_ENABLED: data.WKF_IEL__WISH_COMMENT_ENABLED === "true",
            WKF_IEL__DROP_IN_CENTER_ONLINE_REGISTRATION_ENABLED: data.WKF_IEL__DROP_IN_CENTER_ONLINE_REGISTRATION_ENABLED === "true",
            IEL_INFO__STEP_1_HELP: data.IEL_INFO__STEP_1_HELP?.toString(),
            IEL_INFO__STEP_2_HELP: data.IEL_INFO__STEP_2_HELP?.toString(),
            IEL_INFO__STEP_3_HELP: data.IEL_INFO__STEP_3_HELP?.toString(),
            IEL__ASK_TICKETING_EXTERNAL_NUMBER_ENABLED: data.IEL__ASK_TICKETING_EXTERNAL_NUMBER_ENABLED === "true",
            IEL__ASK_TICKETING_EXTERNAL_NUMBER_MESSAGE: data.IEL__ASK_TICKETING_EXTERNAL_NUMBER_MESSAGE?.toString(),
            IEL__MAP_LAT_LNG_ZOOM: data.IEL__MAP_LAT_LNG_ZOOM?.toString(),
            IEL__SCHOOL_OPTION_COMMENT_ENABLED: data.IEL__SCHOOL_OPTION_COMMENT_ENABLED === "true",
            IEL__DISPLAY_JOURNEY_DETAILS: data.IEL__DISPLAY_JOURNEY_DETAILS === "true",
            IEL__LOGO_IMAGE: data.IEL__LOGO_IMAGE?.toString(),
            WKF__LR_BIRTH_DATE_REQUIRED: data.WKF__LR_BIRTH_DATE_REQUIRED === "true",
            IEL__SUBSCRIPTION_COMMENT_ENABLED: data.IEL__SUBSCRIPTION_COMMENT_ENABLED === "true",
            IEL__TOOLTIP_IMAGE: data.IEL__TOOLTIP_IMAGE?.toString(),
            WKF_IEL__SUBSCRIPTION_DURING_SCHOOL_YEAR_ENABLED: data.WKF_IEL__SUBSCRIPTION_DURING_SCHOOL_YEAR_ENABLED === "true",
        } as SchoolItiParametersPartial;
    }

    getHelpertextRegistrationPopin() {
        return this.get("/helper-popin");
    }

    getParameterFile(parameterKey: string) {
        return this.getFile(["/file/" + parameterKey]);
    }

    getAssetsUrl(src) {
        return urlJoin(this.http.defaults.baseURL || "", "/public/assets/", store.commonCode, src);
    }

    getLogoUrl() {
        return this.getAssetsUrl("/logo");
    }

    getTooltipUrl() {
        return this.getAssetsUrl("/tooltip");
    }
}

export default new SchoolItiParametersService();

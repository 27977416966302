import React, {useMemo, useState} from "react";

import MobileStepper from "@mui/material/MobileStepper";
import {Button, Grid} from "@mui/material";
import Popin from "@/components/common/ui/Popin/Popin";
import {useSettingsContext} from "@/components/common/settings/SettingsContext";
import Images from "@/components/common/styles/Images"

function HelperPopinRNA({isOpen, onClose}) {
    const [activeStep, setActiveStep] = useState(0);
    const {WKF_DOC__PICTURE_MANDATORY: isPhotoMandatory} = useSettingsContext();

    const steps = useMemo(() => {
        return [
            {
                img: Images.helper1,
                header: `Je dois fournir mon dernier avis d'imposition`,
                body: "Il permet de déterminer votre tranche tarifaire. En l’absence de ce document, la tarification maximale sera appliquée.",
                alignElem: "center",
                alt: "un document papier",
            },
            {
                img: Images.helper2,
                header: `Vous ${isPhotoMandatory ? "devrez": "pourrez"} ajouter une photo de votre enfant`,
                body: "En important une photo de votre enfant ou en prenant une photo directement avec votre téléphone.",
                alignElem: "center",
                alt: "un appareil photo",
            },
            {
                img: Images.helper3,
                header: "Votre enfant est en garde alternée et nécessite un second transport ?",
                body: "Lors de l’étape 2, il vous sera possible de définir un second transport ainsi que les informations du deuxième responsable légal.",
                alignElem: "left",
                alt: "une garde alternée",
            },
        ];
    }, [isPhotoMandatory]);

    const finalStep = useMemo(() => activeStep < steps.length - 1, [activeStep, steps.length]);

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            setActiveStep(0);
            onClose();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };
    if (activeStep < steps.length) {
        return (
            <Popin
                title="Comment inscrire un enfant ?"
                isOpen={isOpen}
            >
                <Grid container spacing={2} sx={{p: 2}} direction={"column"} alignItems={"center"}>
                    <Grid item xs={12} textAlign={"center"}>
                        <img
                            src={steps[activeStep].img}
                            alt={steps[activeStep].alt}
                            style={{
                                maxHeight: "200px",
                                height: "100%",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} textAlign={"center"}>
                        {steps[activeStep].header}
                    </Grid>
                    <Grid item xs={12} textAlign={"left"} style={{color: "var(--grey-4)"}}>
                        {steps[activeStep].body}
                    </Grid>
                    <Grid item xs={12}>
                        <MobileStepper
                            variant="dots"
                            steps={steps.length}
                            position="static"
                            activeStep={activeStep}
                            backButton={null}
                            nextButton={null}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={handleNext} variant="text">{finalStep ? "Suivant" : "Terminer"}</Button>
                    </Grid>
                </Grid>
            </Popin>
        );
    } else {
        return null;
    }
}

export default HelperPopinRNA;

import Icon, {IconProps} from "@/components/common/ui/Icon";
import {useSettingsContext} from "@/components/common/settings/SettingsContext";

interface OwnProps {
}

export type ImageProps = OwnProps & Omit<IconProps, "src">;

const Logo = (props: ImageProps) => {
    const {LOGO_IMAGE_URL, AI__NAME} = useSettingsContext();

    if (LOGO_IMAGE_URL) {
        return <Icon {...props} src={LOGO_IMAGE_URL} alt={`Logo ${AI__NAME}`}/>;
    } else {
        return (AI__NAME);
    }
};

export default Logo;
